<template>
  <div class="header">
    <div class="flex align-center justify-between container mx-auto h-100">
      <div class="flex align-center header-left">
        <div class="logo">
          <img src="@/assets/img/logo.png" />
        </div>
      </div>
      <div class="header-right">
        <nav>
          <a href="javascript:void(0)" @click="onClickMenu('index', $event)">首页</a>
          <!-- <a href="http://shop.lupay.cn/" target="_blank">在线商城</a> -->
          <div class="dropdown">
            <button class="dropbtn">产品</button>
            <div class="dropdown-content">
              <a href="javascript:void(0)" @click="onClickMenu('equity', $event)">权益营销</a>
              <a href="javascript:void(0)" @click="onClickMenu('virtualmall', $event)">数字商城</a>
              <a href="javascript:void(0)" @click="onClickMenu('onecardSolution', $event)">定制一卡通</a>
              <a href="javascript:void(0)" @click="onClickMenu('apiRecharge', $event)">Open Api</a>
            </div>
          </div>
          <div class="dropdown">
            <button class="dropbtn">璐付云链</button>
            <div class="dropdown-content">
              <a href="javascript:void(0)" @click="onClickMenu('localLife', $event)">虚拟云链</a>
              <a href="javascript:void(0)" @click="onClickMenu('cloud', $event)">实物云链</a>
            </div>
          </div>
          <a href="javascript:void(0)" @click="onClickMenu('industry', $event)">行业案例</a>
          <a href="javascript:void(0)" @click="onClickMenu('about', $event)">关于我们</a>

          <li class="drop-down flex justify-center tool-info align-center">
            <button class="login" @click="toLogin(2)">登录</button>
            <button class="registry" @click="toLogin(1)">免费注册</button>
          </li>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuList: [
        {
          name: '首页',
          path: '/',
          key: 'index'
        },
        {
          name: '产品',
          Children: [
            {
              name: '权益营销',
              path: '/equity',
              key: 'equity'
            },
            {
              name: '数字商城',
              path: '/virtualmall',
              key: 'virtualmall'
            },
            {
              name: '定制一卡通',
              path: '/onecardSolution',
              key: 'onecardSolution'
            },
            {
              name: 'Open API',
              path: '/apiRecharge',
              key: 'apiRecharge'
            }
          ],
          key: 'product'
        },
        // {
        //   name: '数字运营',
        //   path: '/digital',
        //   key: 'digital'
        // },
        {
          name: '璐付云链',
          Children: [
            {
              name: '虚拟云链',
              path: '/localLife',
              key: 'localLife'
            },
            {
              name: '实物云链',
              path: '/cloud',
              key: 'cloud'
            }
          ]
        },
        {
          name: '行业案例',
          path: '/industry',
          key: 'industry'
        },
        // {
        //   name: '新闻资讯',
        //   path: '/news',
        //   key: 'news'
        // },
        {
          name: '关于我们',
          path: '/about',
          key: 'about'
        }
      ],
      activeIndex: 1
    };
  },
  watch: {
    $route(_item) {
      var activeLinks = document.querySelectorAll('a');
      for (var j = 0; j < activeLinks.length; j++) {
        if (activeLinks[j].innerHTML == _item?.meta?.title) {
          activeLinks[j].classList.add('active');
        }
      }
    }
  },
  mounted() {},
  methods: {
    /**  事件#切换菜单 */
    onClickMenu(key, $event) {
      var activeLinks = document.querySelectorAll('.active');
      for (var j = 0; j < activeLinks.length; j++) {
        activeLinks[j].classList.remove('active');
      }
      $event.target.classList.add('active');
      this.$router.push({
        name: key
      });
    },
    toLogin(type) {
      window.location.href = `http://open.lupay.cn/lupaylogin.html?type=${type}`;
    }
  }
};
</script>

<!-- <style scoped src="../../assets/cmomon/bootstrap.min.css"></style> -->

<style lang="less" scoped>
.header {
  height: 72px;
  transition-duration: 500ms;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 400;
  z-index: 9999;
  &-left {
    width: 140px;
  }
  &-right {
    nav {
      overflow: hidden;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    nav a {
      float: left;
      display: block;
      color: #000;
      text-align: center;
      padding: 14px 16px;
      text-decoration: none;
      width: 120px;
    }

    nav a:hover {
      color: rgba(33, 67, 174, 1);
      font-weight: 500;
    }

    .active {
      color: rgba(33, 67, 174, 1);
      font-weight: 500;
    }

    .dropdown {
      float: left;
      overflow: hidden;
      text-align: center;
      width: 120px;
    }

    .dropdown .dropbtn {
      font-size: 14px;
      border: none;
      outline: none;
      color: #000;
      padding: 14px 16px;
      background-color: inherit;
      font-family: inherit;
      margin: 0;
      position: relative;
    }

    .dropdown .dropbtn:after {
      position: absolute;
      content: '';
      width: 0;
      right: -1px;
      height: 0;
      bottom: 18px;
      border: 4px solid transparent;
      border-top-color: rgba(0, 0, 0, 0.8);
    }

    .dropdown .dropbtn:hover:after {
      position: absolute;
      content: '';
      width: 0;
      bottom: 23px;
      right: -1px;
      height: 0;
      border: 4px solid transparent;
      border-bottom-color: rgba(0, 0, 0, 0.8);
    }

    .navbar a:hover,
    .dropdown:hover .dropbtn {
      color: rgba(33, 67, 174, 1);
      font-weight: 500;
    }

    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #fff;
      min-width: 120px;
      z-index: 1;
      text-align: center;
    }

    .dropdown-content a {
      float: none;
      text-decoration: none;
      display: block;
      text-align: center;
    }

    .dropdown-content a:hover {
      background-color: #f7f7f7;
      color: rgba(33, 67, 174, 1);
      font-weight: 500;
    }

    .dropdown:hover .dropdown-content {
      display: block;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }

  .tool-info {
    border-left: 1px solid #d1d1d1;
    .registry {
      margin-right: 15px;
      font-size: 13px;
      height: 72px;
      font-size: 14px;
      color: #fff;
      line-height: 72px;
      background-color: #0052d9;
      cursor: pointer;
      width: 100px;
    }
    .login {
      width: 100px;
      height: 30px;
      padding: 5px;
      font-size: 14px;
      border-radius: 5px;
    }
  }
}
.header:hover {
  background-color: @lupay-bg-color-white;
  transition-duration: 200ms;
}
</style>
