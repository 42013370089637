<template>
  <div class="footer">
    <div class="container mx-auto">
      <div class="card flex">
        <div class="info flex-1">
          <img src="@/assets/img/logo.png" />
          <p class="title">联系我们</p>
          <p class="value">TEL: {{ telPhone }}</p>
          <p class="value">EMAIL: {{ email }}</p>
          <p class="value">地址: {{ address }}</p>
        </div>
        <div class="tool-info flex-1">
          <p class="title">产品介绍</p>
          <router-link to="/equity">
            <p class="value">权益营销</p>
          </router-link>
          <router-link to="/virtualmall">
            <p class="value">数字商城</p>
          </router-link>
          <router-link to="/apiRecharge">
            <p class="value">Open Api</p>
          </router-link>
          <router-link to="/onecardSolution">
            <p class="value">定制一卡通</p>
          </router-link>
          <router-link to="/localLife">
            <p class="value">璐付云链</p>
          </router-link>
        </div>
        <div class="tool-info flex-1">
          <p class="title">解决方案</p>
          <p class="value programme">营销方案</p>
          <p class="value programme">积分方案</p>
          <p class="value programme">技术方案</p>
        </div>
        <div class="tool-info flex-1">
          <p class="title">企业愿景</p>
          <p class="value1">创造价值</p>
          <p class="value1">经营幸福</p>
          <p class="value1">改变生活</p>
        </div>
      </div>
      <div class="line"></div>
      <!-- 备案信息 -->
      <div class="filing-information flex justify-center align-center h-100">
        <p>
          网站备案编号：<a href="https://beian.miit.gov.cn/" target="_blank">{{ netFilingNo }}</a>
        </p>
        <!-- <p>网站备案编号：渝ICP备 14003695号-3</p> -->
        <p>公安备案编号：{{ securityFilingNo }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      telPhone: window.config.telPhone || '4000022253',
      email: window.config.email || 'hezuo@lupay.cn',
      address: window.config.address || '重庆市江北区北滨一路招商江湾城363号附23号3楼',
      netFilingNo: window.config.netFilingNo || '渝ICP备 14003695号-1',
      securityFilingNo: window.config.securityFilingNo || '渝公网安备 50010802001626号'
    };
  }
};
</script>

<style lang="less" scoped>
.footer {
  background: rgba(224, 240, 255, 1);
  padding-top: 48px;
  .info {
    img {
      width: 149px;
      height: 40px;
    }
    .title {
      color: rgba(0, 0, 0, 0.75);
      font-size: 14px;
      margin-top: 40px;
    }
    .value {
      color: rgba(0, 0, 0, 0.55);
      font-size: 14px;
      margin-top: 20px;
    }
    margin-right: 170px;
    min-width: 360px;
  }
  .tool-info {
    .title {
      color: rgba(0, 0, 0, 0.75);
      font-size: 14px;
      margin-bottom: 40px;
    }
    .value {
      color: rgba(0, 0, 0, 0.55);
      font-size: 12px;
      margin-top: 16px;
      padding-left: 3px;
      cursor: pointer;
    }

    .value1 {
      color: rgba(0, 0, 0, 0.55);
      font-size: 12px;
      margin-top: 16px;
      padding-left: 3px;
    }
    .programme {
      position: relative;
      width: 60px;
    }
    .programme:hover::before {
      content: '扫一扫获取方案';
      display: block;
      width: 120px;
      top: 24px;
      left: -20px;
      position: absolute;
      background-size: 100% 100%;
      padding: 2px;
      border: 1px solid #f4f4f4;
      opacity: 1;
      transition-duration: 300ms;
      background-color: #fff;
      z-index: 8;
      text-align: center;
    }
    .programme:hover::after {
      content: '';
      display: block;
      width: 120px;
      height: 120px;
      top: 44px;
      left: -20px;
      position: absolute;
      background-image: url('../../assets/img/lupaywechat.png');
      background-size: 100% 100%;
      padding: 2px;
      border: 1px solid #f4f4f4;
      opacity: 1;
      transition-duration: 300ms;
      z-index: 8;
    }
  }
  .line {
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.2);
    margin-top: 60px;
  }
  .filing-information {
    color: rgba(0, 0, 0, 0.55);
    font-size: 12px;
    height: 40px;
    p {
      margin: 0 35px;
    }
  }
}
</style>
