import Vue from 'vue';
import App from './App.vue';
import router from './router';
import BaiduMap from 'vue-baidu-map';

import './assets/tailwindcss.css';
import 'animate.css';
import './styles/index.less';

import wow from 'wowjs'; //全局导入wow.js
Vue.prototype.$wow = wow; //全局注册到

Vue.config.productionTip = false;

Vue.use(BaiduMap, {
  ak: 'EF7wIf7pwgDXg3gSsoHUuNAMi8EYco1D'
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
