<template>
  <div class="tool-bar">
    <div class="content">
      <div class="card flex align-center justify-center" @click="onBackUp">
        <img src="@/assets/img/back-up.png" />
        <div class="title">回到顶部</div>
      </div>
      <div class="card flex align-center justify-center">
        <img src="@/assets/img/phone.png" />
        <div class="title">400-0022-253</div>
      </div>
      <!-- <div class="card flex align-center justify-center">
        <img src="@/assets/img/on-line.png" />
        <div class="title">在线客服</div>
      </div> -->
      <div class="card card-last flex align-center justify-center">
        <img src="@/assets/img/wechat.png" />
        <div class="title">微信二维码</div>
      </div>
      <div class="card-1">
        <div class="qrcode-box flex justify-center">
          <img src="@/assets/img/lupaywechat.png" class="qrcode" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 右侧工具栏目
export default {
  data() {
    return {
      drag: 10
    };
  },
  methods: {
    /** 事件#回到顶部 */
    onBackUp() {
      this.scrollToTop();
    },
    /** 事件#回到顶部 */
    scrollToTop() {
      // 距离顶部的距离
      const gap = document.documentElement.scrollTop || document.body.scrollTop;
      if (gap > 0) {
        window.requestAnimationFrame(this.scrollToTop);
        window.scrollTo(0, gap - gap / this.drag);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.tool-bar {
  padding: 15px;
  .card {
    background: rgba(34, 78, 219, 1);
    border-radius: 6px;
    padding: 0px 10px;
    margin-top: 2px;
    height: 56px;
    cursor: pointer;
    img {
      width: 35px;
      height: 35px;
    }
    .title {
      color: rgba(255, 255, 255, 1);
      font-size: 14px;
      width: 0;
      overflow: hidden;
      transition-duration: 100ms;
    }
  }
  .qrcode {
    width: 0;
    height: 0;
    transition-duration: 100ms;
  }
  .content:hover {
    .title {
      transition-duration: 100ms;
      width: 110px;
      font-size: 14px;
      margin: 0 10px;
    }
    .qrcode-box {
      background: rgba(34, 78, 219, 1);
      padding: 10px 15px 15px 15px;
    }
    .qrcode {
      width: 154px;
      height: 150px;
      display: block;
      transition-duration: 100ms;
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
      transition-duration: 100ms;
    }
    .card-last {
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
}
</style>
