import Vue from 'vue';
import Router from 'vue-router';

const constantRouterMap = [
  {
    path: '/',
    name: 'index',
    meta: { title: '首页' },
    component: () => import('@/views/index/index')
  },
  {
    path: '/apiRecharge',
    name: 'apiRecharge',
    meta: { title: 'Open Api' },
    component: () => import('@/views/product/apiRecharge')
  },
  {
    path: '/equity',
    name: 'equity',
    meta: { title: '权益营销' },
    component: () => import('@/views/product/equity')
  },
  {
    path: '/virtualmall',
    name: 'virtualmall',
    meta: { title: '数字商城' },
    component: () => import('@/views/product/virtualmall')
  },
  {
    path: '/onecardSolution',
    name: 'onecardSolution',
    meta: { title: '定制一卡通' },
    component: () => import('@/views/product/onecardSolution')
  },
  {
    path: '/localLife',
    name: 'localLife',
    meta: { title: '虚拟云链' },
    component: () => import('@/views/product/localLife')
  },
  {
    path: '/digital',
    name: 'digital',
    meta: { title: '数字运营' },
    component: () => import('@/views/digital/index')
  },
  {
    path: '/industry',
    name: 'industry',
    meta: { title: '行业案例' },
    component: () => import('@/views/industry/index')
  },
  {
    path: '/cloud',
    name: 'cloud',
    meta: { title: '实物云链' },
    component: () => import('@/views/cloud/index')
  },
  {
    path: '/about',
    name: 'about',
    meta: { title: '关于我们' },
    component: () => import('@/views/about/index')
  },
  {
    path: '/news',
    name: 'news',
    meta: { title: '新闻资讯' },
    component: () => import('@/views/news/index')
  },
  {
    path: '/news/details',
    name: 'details',
    meta: { title: '新闻资讯详情' },
    component: () => import('@/views/news/details')
  }
];

Vue.use(Router);

export default new Router({
  mode: 'history',
  //   base: process.env.BASE_URL,
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRouterMap
});
