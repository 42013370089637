<template>
  <div id="app">
    <Header class="app-header" v-if="activeName === 'index'" />
    <Header v-else class="nohome-header" />
    <div class="app-content">
      <router-view />
    </div>
    <Footer />
    <ToolBar class="toobar" v-if="scrollTop > 1200" />
  </div>
</template>

<script>
// components
import Header from '@/components/layout/Header';
import Footer from '@/components/layout/Footer';
import ToolBar from '@/components/common/ToolBar';

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    ToolBar
  },
  data() {
    return {
      activeName: '',
      scrollTop: 0
    };
  },
  watch: {
    $route(_item) {
      this.activeName = _item.name;
    },
    scrollTop(_value) {
      if (_value > 400) {
        if (this.activeName == 'index') {
          let appHeader = document.getElementsByClassName('app-header');
          appHeader && appHeader[0].classList.add('app-header-fixed');
        } else {
          let nohomeHeader = document.getElementsByClassName('nohome-header');
          nohomeHeader && nohomeHeader[0].classList.add('nohome-header-fixed');
        }
      }
      if (_value === 0) {
        if (this.activeName == 'index') {
          let appHeader = document.getElementsByClassName('app-header');
          appHeader && appHeader[0].classList.remove('app-header-fixed');
        } else {
          let nohomeHeader = document.getElementsByClassName('nohome-header');
          nohomeHeader && nohomeHeader[0].classList.remove('nohome-header-fixed');
        }
      }
    }
  },
  created() {
    if (document.getElementById('Loading')) document.getElementById('Loading').remove();
  },
  mounted() {
    this.onScrollTop();
    window.addEventListener('scroll', this.onScrollTop);
  },
  methods: {
    /** 事件#监听滚动条  */
    onScrollTop() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      this.scrollTop = scrollTop;
    }
  }
};
</script>

<style lang="less" scoped>
#app {
  position: relative;
  min-width: 1300px;
  .app-header {
    width: 100%;
    position: absolute;
  }

  .nohome-header {
    width: 100%;
    position: relative;
  }

  .app-header-fixed {
    background-color: #fff;
    position: fixed;
    animation: downAbimation 0.3s;
    box-shadow: 0px 3px 10px rgba(34, 78, 219, 0.1);
  }

  .nohome-header-fixed {
    background-color: #fff;
    position: fixed;
    animation: downAbimation 0.3s;
    box-shadow: 0px 3px 10px rgba(34, 78, 219, 0.1);
  }

  @keyframes downAbimation {
    from {
      top: -70px;
    }
    to {
      top: 0;
    }
  }

  @-webkit-keyframes downAbimation /* Safari and Chrome */ {
    from {
      top: -70px;
    }
    to {
      top: 0;
    }
  }

  .toobar {
    position: fixed;
    top: calc(50% - 100px);
    right: 0;
    z-index: 9999999;
  }
}
</style>
